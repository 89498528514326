import { styled } from "@mui/system";
import IMAGES from "../../../assets/images";
import { Box, Button, Typography } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";

const MainWrap = styled(Box)(() => ({
  width: "100%",
  height: "345px",
  backgroundImage: `url(${IMAGES.UNDER_HEADER_IMAGE})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  justifyContent: "space-between",
}));

const FindUsWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  paddingRight: "50px",
  height: "50px",
  width: "250px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const FindUsFacebookIcon = styled(FacebookRoundedIcon)(({ theme }) => ({
  color: "white",
}));

const DarkShadowWrap = styled(Box)(({ theme }) => ({
  width: "600px",
  height: "100%",
  backgroundImage: `url(${IMAGES.UNDER_HEADER_IMAGE_COVER})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: "none",
    backgroundColor: `rgba(6, 6, 25, 0.62)`,
    width: "100%",
  },
}));

const ContentColumnWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  height: "100%",
  width: "65%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CompanyNameTypography = styled(Typography)(() => ({
  fontSize: "32px",
  color: "white",
  fontWeight: "bolder",
}));

const CompanyDescriptionTypography = styled(Typography)(() => ({
  fontSize: "12px",
  color: "white",
  fontWeight: "100",
  textAlign: "center",
  paddingLeft: "20px",
  paddingRight: "20px",
}));

const ContactUsButton = styled(Button)(() => ({
  background: "linear-gradient(90deg, #4A598B, #73B8B4, #A6D1B2)",
  color: "white",
  fontSize: "10px",
  fontWeight: "bolder",
  width: "170px",
  height: "35px",
  border: "1px solid white",
  "& .MuiButton-label": {
    fontWeight: "800 !important",
  },
}));

export {
  MainWrap,
  FindUsWrap,
  FindUsFacebookIcon,
  DarkShadowWrap,
  ContentColumnWrap,
  CompanyNameTypography,
  CompanyDescriptionTypography,
  ContactUsButton,
};
