import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const MainWrap = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  backgroundColor: "#01172B",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    overfloxY: "auto",
  },
}));

const CloseButtonWrap = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "end",
}));

const CloseButtonIcon = styled(CloseIcon)(() => ({
  color: "white",
  fontSize: "32px",
}));

const HeadingWrap = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "27px",
  fontWeight: "bolder",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

const ContentWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexGrow: "1",
  height: "280px",
}));

const ArrowWrap = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const LeftArrowIcon = styled(ArrowLeftIcon)(() => ({
  height: "60px",
  width: "60px",
  color: "white",
}));

const RightArrowIcon = styled(ArrowRightIcon)(() => ({
  height: "60px",
  width: "60px",
  color: "white",
}));

const MainContentWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    height: "85%",
    overflowX: "hidden",
    flexDirection: "column",
    padding: "0 50px 0 50px",
  },
}));

const TelekomunikacijeDiv = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const TelekomunikacijeDivDva = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: "150px",
  },
}));

const MainContentDescription = styled("div")(() => ({
  textAlign: "start",
}));

const MainContentDescriptionTypography = styled(Typography)(() => ({
  color: "white",
  fontSize: "14px",
}));

const VerticalDivider = styled("div")(({ theme }) => ({
  height: "65%",
  width: "1px",
  backgroundColor: "white",
  margin: "0 20px 0 20px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const MainContentImageWrap = styled("div")(() => ({}));

const MainContentImageWrapTelekomunikacije = styled("div")(() => ({
  marginLeft: "40px",
}));

const MainContentImage = styled("img")(() => ({}));

export {
  MainWrap,
  CloseButtonWrap,
  CloseButtonIcon,
  HeadingWrap,
  HeadingTypography,
  ContentWrap,
  ArrowWrap,
  LeftArrowIcon,
  RightArrowIcon,
  MainContentWrap,
  MainContentDescription,
  MainContentDescriptionTypography,
  VerticalDivider,
  MainContentImageWrap,
  MainContentImage,
  TelekomunikacijeDiv,
  TelekomunikacijeDivDva,
  MainContentImageWrapTelekomunikacije,
};
