import { styled } from "@mui/system";

const MainWrap = styled("div")(({ theme }) => ({
  width: "160px",
  height: "270px",
  position: "absolute",
  borderTop: "4px solid #B9DCDA",
  borderBottom: "4px solid #B9DCDA",
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
}));

const SecondWrap = styled("div")(() => ({
  borderTop: "4px solid #B9DCDA",
  borderBottom: "4px solid #B9DCDA",
  width: "160px",
  height: "270px",
  transform: "rotate(60deg)",
  position: "absolute",
  backgroundColor: "white",
}));

const ThirdWrap = styled("div")(() => ({
  borderTop: "4px solid #B9DCDA",
  borderBottom: "4px solid #B9DCDA",
  width: "160px",
  height: "270px",
  transform: "rotate(300deg)",
  position: "absolute",
  backgroundColor: "white",
}));

const DescriptionWrap = styled("div")(() => ({
  width: "220px",
  height: "200px",
  zIndex: "999",
  paddingTop: "50px",
  marginTop: "15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  textAlign: "center",
}));

export { MainWrap, SecondWrap, ThirdWrap, DescriptionWrap };
