import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import IMAGES from "../../../assets/images";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

const MainWrap = styled("div")(({ theme }) => ({
  width: "100%",
  height: "345px",
  backgroundColor: "#01172B",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

const DarkShadowWrap = styled(Box)(({ theme }) => ({
  width: "600px",
  height: "100%",
  backgroundImage: `url(${IMAGES.UNDER_HEADER_IMAGE_COVER})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: "none",
    backgroundColor: `rgba(6, 6, 25, 0.62)`,
    width: "100%",
  },
}));

const ContentColumnWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "30px",
  height: "100%",
  width: "65%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "30px 0 30px 0",
  },
}));

const FooterLogoImg = styled("img")(() => ({}));

const FooterFindUs = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
}));

const FooterFindUsTypography = styled(Typography)(() => ({
  color: "white",
  fontSize: "16px",
  fontWeight: "bolder",
}));

const FooterFindUsFacebookIcon = styled(FacebookRoundedIcon)(({ theme }) => ({
  color: "white",
}));

const FooterContantUsWrap = styled(Box)(({ theme }) => ({
  height: "345px",
  width: "70%",
  display: "flex",
  justifyContent: "center",
  gap: "10%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column",
    padding: "40px 0 30px 0",
  },
}));

const FooterContantUsNavigationWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const FooterContantUsNavigationButton = styled(Button)(() => ({
  fontSize: "14px",
  fontWeight: "100",
  color: "white",
  textDecoration: "none",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
}));

const FooterContantUsInformationWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "20px",
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    marginBottom: "20px",
    marginLeft: "5px",
    marginRight: "5px",
  },
}));

const FooterContantUsInformationDataWrap = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const FooterContantUsInformationPhoneDataWrap = styled(Box)(() => ({
  display: "flex",
  gap: "40px",
}));

const FooterContantUsInformationTypography = styled(Typography)(() => ({
  color: "white",
  fontWeight: "bolder",
  fontSize: "12px",
}));

const FooterContantUsInformationPhoneIcon = styled(PhoneOutlinedIcon)(() => ({
  color: "white",
  width: "20px",
  height: "20px",
}));

const FooterContantUsInformationEmailIcon = styled(EmailOutlinedIcon)(() => ({
  color: "white",
  width: "20px",
  height: "20px",
}));

const FooterContantUsInformationLocationIcon = styled(PlaceOutlinedIcon)(
  () => ({
    color: "white",
    width: "20px",
    height: "20px",
  })
);

export {
  MainWrap,
  DarkShadowWrap,
  ContentColumnWrap,
  FooterLogoImg,
  FooterFindUs,
  FooterFindUsTypography,
  FooterFindUsFacebookIcon,
  FooterContantUsWrap,
  FooterContantUsNavigationWrap,
  FooterContantUsNavigationButton,
  FooterContantUsInformationWrap,
  FooterContantUsInformationDataWrap,
  FooterContantUsInformationPhoneDataWrap,
  FooterContantUsInformationTypography,
  FooterContantUsInformationPhoneIcon,
  FooterContantUsInformationEmailIcon,
  FooterContantUsInformationLocationIcon,
};
