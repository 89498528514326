import { Box, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";

const TopMainWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "100px",
  [theme.breakpoints.down("md")]: {
    padding: "0px",
  },
}));

const MainWrap = styled("div")(({ theme }) => ({
  width: "357px",
  height: "612px",
  position: "absolute",
  borderTop: "4px solid #B9DCDA",
  borderBottom: "4px solid #B9DCDA",
  display: "flex",
  alignItems: "center",
  marginTop: "335px",
  [theme.breakpoints.down("sm")]: {
    border: "0px",
    position: "relative",
    margin: "0px",
  },
}));

const HexagonFormWrap = styled("div")(() => ({
  height: "100%",
  width: "100%",
}));

const SecondWrap = styled("div")(({ theme }) => ({
  borderTop: "4px solid #B9DCDA",
  borderBottom: "4px solid #B9DCDA",
  width: "357px",
  height: "612px",
  transform: "rotate(60deg)",
  position: "absolute",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const ThirdWrap = styled("div")(({ theme }) => ({
  borderTop: "4px solid #B9DCDA",
  borderBottom: "4px solid #B9DCDA",
  width: "357px",
  height: "612px",
  transform: "rotate(300deg)",
  position: "absolute",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const Form = styled("form")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "30px",
}));

const FormButtonWrap = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "end",
}));

const FormButton = styled(Button)(() => ({
  background: "linear-gradient(90deg, #4A598B, #73B8B4, #A6D1B2)",
  color: "white",
  fontSize: "10px",
  fontWeight: "bolder",
  width: "96px",
  height: "35px",
  border: "1px solid white",
  "& .MuiButton-label": {
    fontWeight: "800 !important",
  },
}));

const FormTextField = styled(TextField)(() => ({
  color: "white",
  "& .MuiOutlinedInput-input": {
    borderColor: "white",
    color: "white",
  },
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
}));

export {
  TopMainWrap,
  MainWrap,
  SecondWrap,
  ThirdWrap,
  HexagonFormWrap,
  Form,
  FormTextField,
  FormButtonWrap,
  FormButton,
};
