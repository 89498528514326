import { styled } from "@mui/system";
import { AppBar, Button, IconButton, Paper, Toolbar } from "@mui/material";

const MainWrap = styled("div")(() => ({
  width: "100%",
  height: "70px",
}));

const CustomHeaderAppBar = styled(AppBar)(({ theme }) => ({
  background: "linear-gradient(90deg, #070F24, #73B8B4, #A6D1B2)",
  height: "70px",
  display: "flex",
  justifyContent: "center",
}));

const CustomHeaderToolBar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const Logo = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    marginLeft: "-60px",
  },
}));

const CustomButton = styled(Button)(() => ({
  color: "white",
  textDecoration: "none",
  marginLeft: "20px",
  marginRight: "20px",
  fontWeight: "100",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
}));

const BurgerMenuPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  background: "none",
  boxShadow: "none",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const BurgerMenuIconButton = styled(IconButton)(({ theme }) => ({
  color: "white",
}));

const HeaderItemsPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  background: "none",
  boxShadow: "none",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export {
  MainWrap,
  CustomHeaderAppBar,
  CustomHeaderToolBar,
  CustomButton,
  Logo,
  BurgerMenuPaper,
  BurgerMenuIconButton,
  HeaderItemsPaper,
};
