import { styled } from "@mui/system";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Box, Button, Typography } from "@mui/material";

const MainWrap = styled("div")(({ theme }) => ({
  width: "100%",
  height: "538px",
  marginTop: "170px",
  background: `linear-gradient(90deg, #06152F, #A6D1B2)`,
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    height: "auto",
  },
}));

const ContactInformationWrap = styled("div")(({ theme }) => ({
  width: "50%",
  height: "538px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-evenly",
  margin: "0 0 0 10%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0px",
    alignItems: "center",
  },
}));

const ContactInformationTypographyWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    alignItems: "center",
    textAlign: "center",
  },
}));

const ContactInformationTypography = styled(Typography)(({ theme }) => ({
  fontSize: "36px",
  color: "white",
  marginTop: "114px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0px",
  },
}));

const ContactInformationJIBWrap = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    alignItems: "center",
    textAlign: "center",
  },
}));

const ContactInformationJIPTypography = styled(Typography)(() => ({
  fontSize: "14px",
  color: "white",
}));

const ContantUsInformationWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "20px",
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    margin: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

const ContantUsInformationDataWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "start",
  },
}));

const ContantUsInformationPhoneDataWrap = styled(Box)(() => ({
  display: "flex",
  gap: "30px",
}));

const ContantUsInformationTypography = styled(Typography)(() => ({
  color: "white",
  fontWeight: "bolder",
  fontSize: "12px",
}));

const ContantUsInformationPhoneIcon = styled(PhoneOutlinedIcon)(() => ({
  color: "white",
  width: "20px",
  height: "20px",
}));

const ContantUsInformationEmailIcon = styled(EmailOutlinedIcon)(() => ({
  color: "white",
  width: "20px",
  height: "20px",
}));
const ContantUsInformationLocationIcon = styled(PlaceOutlinedIcon)(() => ({
  color: "white",
  width: "20px",
  height: "20px",
}));

const HowToReachUsButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #4A598B, #73B8B4, #A6D1B2)",
  color: "white",
  fontSize: "10px",
  fontWeight: "bolder",
  width: "141px",
  height: "35px",
  border: "1px solid white",
  "& .MuiButton-label": {
    fontWeight: "800 !important",
  },
}));

const HowToReachUsButtonWrap = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ContactFormWrap = styled("div")(({ theme }) => ({
  width: "50%",
  height: "538px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
}));

export {
  MainWrap,
  ContactInformationWrap,
  ContactFormWrap,
  ContantUsInformationWrap,
  ContantUsInformationDataWrap,
  ContantUsInformationPhoneDataWrap,
  ContantUsInformationTypography,
  ContantUsInformationPhoneIcon,
  ContantUsInformationEmailIcon,
  ContantUsInformationLocationIcon,
  HowToReachUsButton,
  HowToReachUsButtonWrap,
  ContactInformationTypographyWrap,
  ContactInformationTypography,
  ContactInformationJIBWrap,
  ContactInformationJIPTypography,
};
