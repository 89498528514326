import React, { FC } from "react";
import {
  AllRightReservedTypography,
  AllRightReservedWrap,
  ContentWrap,
  GetInTouchTypography,
  GetInTouchWrap,
  MainWrap,
} from "./footerUI/CopyrightFooterUI";
import { Button, Tooltip } from "@mui/material";

const CopyrightFooter: FC = () => {
  const onGmailClickHander = () => {};

  const onBeehanceClickHander = () => {
    window.open("https://www.behance.net/marijastupardesigner", "_blank");
  };

  const onViberClickHander = () => {};
  return (
    <MainWrap>
      <ContentWrap>
        <AllRightReservedWrap>
          <AllRightReservedTypography>
            © WEBSITE DESIGN BY <strong>MARIA</strong>. ALL RIGHTS RESERVED.
          </AllRightReservedTypography>
        </AllRightReservedWrap>
        <GetInTouchWrap>
          <GetInTouchTypography>GET IN TOUCH:</GetInTouchTypography>
          <Tooltip title="marija.stupar451@gmail.com">
            <Button onClick={onGmailClickHander}>
              <GetInTouchTypography>GMAIL</GetInTouchTypography>
            </Button>
          </Tooltip>
          <Tooltip title="Marija Stupar">
            <Button onClick={onBeehanceClickHander}>
              <GetInTouchTypography>BEEHANCE</GetInTouchTypography>
            </Button>
          </Tooltip>
          <Tooltip title="+387 65 451 706">
            <Button onClick={onViberClickHander}>
              <GetInTouchTypography>VIBER</GetInTouchTypography>
            </Button>
          </Tooltip>
        </GetInTouchWrap>
      </ContentWrap>
    </MainWrap>
  );
};

export default CopyrightFooter;
