import React, { FC } from "react";
import {
  MainWrap,
  WhoWeAreDescriptionTypography,
  WhoWeAreTypography,
} from "./about_usUI/AboutUsUI";

const AboutUs: FC = () => {
  return (
    <MainWrap>
      <WhoWeAreTypography>
        KO SMO <strong>MI</strong>
      </WhoWeAreTypography>
      <WhoWeAreDescriptionTypography sx={{ fontWeight: "100" }}>
        MI SMO KOMPANIJA KOJA USPJEŠNO POSLUJE VIŠE OD 25 GODINA NA PODRUČJU
        BOSNE I HERCEGOVINE. NAŠ CILJ JE UDOVOLJITI SVIM VAŠIM POTREBAMA U OVIM
        OBLASTIMA, TE VAM OMOGUĆITI JEDNOSTAVAN I SIGURAN PRISTUP SVIM NAŠIM
        USLUGAMA PUTEM NAŠE WEB STRANICE.
      </WhoWeAreDescriptionTypography>
    </MainWrap>
  );
};

export default AboutUs;
