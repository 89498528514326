import React, { FC, useState } from "react";
import {
  BurgerMenuIconButton,
  BurgerMenuPaper,
  CustomButton,
  CustomHeaderAppBar,
  CustomHeaderToolBar,
  HeaderItemsPaper,
  Logo,
  MainWrap,
} from "./headerUI/HeaderUI";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IMAGES from "../../assets/images";

interface HeaderProps {
  goToServices: () => void;
  goToHome: () => void;
  goToContact: () => void;
  goToAboutUs: () => void;
}

const Header: FC<HeaderProps> = ({
  goToServices,
  goToAboutUs,
  goToContact,
  goToHome,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <MainWrap>
      <CustomHeaderAppBar>
        <CustomHeaderToolBar>
          <Logo src={IMAGES.LOGO_HEADER} onClick={goToHome} />
          <BurgerMenuPaper>
            <BurgerMenuIconButton onClick={toggleMenu}>
              <MenuIcon />
            </BurgerMenuIconButton>
          </BurgerMenuPaper>
          <HeaderItemsPaper>
            <div>
              <CustomButton color="inherit" onClick={goToHome}>
                Početna
              </CustomButton>
              <CustomButton color="inherit" onClick={goToAboutUs}>
                O nama
              </CustomButton>
              <CustomButton color="inherit" onClick={goToServices}>
                Usluge
              </CustomButton>
              <CustomButton color="inherit" onClick={goToContact}>
                Kontakt
              </CustomButton>
            </div>
          </HeaderItemsPaper>
        </CustomHeaderToolBar>
      </CustomHeaderAppBar>

      <Drawer anchor="top" open={menuOpen} onClose={toggleMenu}>
        <List>
          <ListItem
            onClick={() => {
              goToHome();
              toggleMenu();
            }}
          >
            <ListItemText primary="Početna" />
          </ListItem>
          <ListItem
            onClick={() => {
              goToAboutUs();
              toggleMenu();
            }}
          >
            <ListItemText primary="O nama" />
          </ListItem>
          <ListItem
            onClick={() => {
              goToServices();
              toggleMenu();
            }}
          >
            <ListItemText primary="Usluge" />
          </ListItem>
          <ListItem
            onClick={() => {
              goToContact();
              toggleMenu();
            }}
          >
            <ListItemText primary="Kontakt" />
          </ListItem>
        </List>
      </Drawer>
    </MainWrap>
  );
};

export default Header;
