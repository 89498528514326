import React, { FC, useState } from "react";
import {
  TopMainWrap,
  Form,
  FormButton,
  FormButtonWrap,
  FormTextField,
  MainWrap,
  SecondWrap,
  ThirdWrap,
  HexagonFormWrap,
} from "./componentsUI/HexagonUI";
import emailjs from "emailjs-com";
import { Box, Modal, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const SERVICE_ID = "service_5954xdj";
const TEMPLATE_ID = "template_e9lvwtv";
const USER_ID = "MKOhx_GakC0Wgao0s";

const Hexagon: FC = () => {
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState<any>();
  const [modalIcon, setModalIcon] = useState<any>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        setModalIcon(
          <CheckCircleIcon
            sx={{ color: "green", width: "40px", height: "40px" }}
          />
        );
        setModalText("Hvala Vam na upitu!");
        handleOpen();
      },
      (error) => {
        setModalIcon(
          <ErrorIcon sx={{ color: "red", width: "40px", height: "40px" }} />
        );
        setModalText(
          "Nažalost, trenutno nismo u mogućnosti proslijediti poruku.Pokusajte kasnije"
        );
        handleOpen();
      }
    );
    e.target.reset();
  };

  return (
    <TopMainWrap>
      <MainWrap>
        <SecondWrap />
        <ThirdWrap />
        <HexagonFormWrap>
          <Form onSubmit={handleOnSubmit}>
            <FormTextField
              variant="outlined"
              label="Ime i prezime"
              name="from_name"
              placeholder="Ime i prezime"
              required
            />
            <FormTextField
              variant="outlined"
              label="Email"
              name="from_email"
              placeholder="Email"
              required
            />
            <FormTextField
              variant="outlined"
              label="Broj telefona"
              name="from_contact"
              placeholder="Broj telefona"
            />
            <FormTextField
              variant="outlined"
              label="Poruka"
              name="message"
              placeholder="Poruka"
              rows={3}
              multiline
              required
            />
            <FormButtonWrap>
              <FormButton type="submit">POŠALJI</FormButton>
            </FormButtonWrap>
          </Form>
        </HexagonFormWrap>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              {modalIcon}
            </Typography>
            <Typography sx={{ mt: 2 }}>{modalText}</Typography>
          </Box>
        </Modal>
      </MainWrap>
    </TopMainWrap>
  );
};

export default Hexagon;
