import React, { FC, useState } from "react";
import {
  BackgroundWrap,
  BottomLeftBackgroundWrap,
  BottomRightBackgroundWrap,
  MainWrap,
  ContentWrap,
  HexagonsWrap,
  OurServicesWrap,
  OurServicesHeading,
  OurServicesButton,
  FirstRowHexagonsWrap,
  SecondRowHexagonsWrap,
  ThirdRowHexagonsWrap,
} from "./our_servicesUI/OurServicesUI";
import Hexagon from "./components/Hexagon";
import MoreOnUsModal from "./MoreOnUsModal";
import { Modal } from "@mui/material";

const OurServices: FC = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <MainWrap>
      <BackgroundWrap />
      <BottomLeftBackgroundWrap />
      <BottomRightBackgroundWrap />
      <ContentWrap>
        <OurServicesWrap>
          <OurServicesHeading>
            NAŠE <strong>USLUGE</strong>
          </OurServicesHeading>
          <OurServicesButton onClick={handleOpen}>
            OPŠIRNIJE...
          </OurServicesButton>
          <Modal open={open} onClose={handleClose}>
            <MoreOnUsModal closeIcon={handleClose} />
          </Modal>
        </OurServicesWrap>
        <HexagonsWrap>
          <FirstRowHexagonsWrap>
            <Hexagon
              heading="TELEKOMUNIKACIJE"
              subheading="PRODAJA I SERVIS RADIO UREÐAJA, REPETITORA, ANTENA I DRUGE OPREME ZA RADIOKOMUNIKACIJE, POZNATIH SVJETSKIH PROIZVOÐAČA..."
            />
            <Hexagon
              heading="LIFTOVI"
              subheading="PRODAJA, SERVIS I ODRŽAVANJE LIFTOVA. DELOVI I SISTEMI ZA LIFTOVE POZNATIH PROIZVOÐAČA..."
            />
          </FirstRowHexagonsWrap>
          <SecondRowHexagonsWrap>
            <Hexagon
              heading="SAOBRAĆAJ"
              subheading="PRODAJA, SERVIS I ODRŽAVANJE UREÐAJA I SISTEMA ZA PRIKUPLJANJE I OBRADU PODATAKA O BROJU, BRZINI I VRSTI VOZILA U SAOBRAĆAJU..."
            />
            <Hexagon
              heading="MEDICINSKA OPREMA"
              subheading="PRODAJA I SERVIS PLASMA STERILIZATORA, MAŠINA ZA PRANJE LABORATORIJSKOG POSUÐA, RAČUNARA SA GOVORNIM SOFTVEROM ZA SLIJEPA I SLABOVIDA LICA..."
            />
          </SecondRowHexagonsWrap>
          <ThirdRowHexagonsWrap>
            <Hexagon
              heading="JAVNO UZBUNJIVANJE"
              subheading="PROJEKTOVANJE, PRODAJA, SERVIS I ODRŽAVANJE UREÐAJA I SISTEMA ZA JAVNO OBAVJEŠTAVANJE I UZBUNJIVANJE..."
            />
            <Hexagon
              heading="INFORMATIKA"
              subheading="PRODAJA I SERVIS PERSONALNIH RAČUNARA, ŠTAMPAČA I KOMUNIKACIONE OPREME..."
            />
            <Hexagon
              heading="SEIZMOLOGIJA"
              subheading="PRODAJA I SERVIS SEIZMOLOŠKIH INSTRUMENATA..."
            />
          </ThirdRowHexagonsWrap>
        </HexagonsWrap>
      </ContentWrap>
    </MainWrap>
  );
};

export default OurServices;
