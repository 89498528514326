import React, { FC } from "react";
import {
  FooterFindUs,
  FooterLogoImg,
  ContentColumnWrap,
  DarkShadowWrap,
  MainWrap,
  FooterFindUsTypography,
  FooterFindUsFacebookIcon,
  FooterContantUsWrap,
  FooterContantUsInformationDataWrap,
  FooterContantUsInformationEmailIcon,
  FooterContantUsInformationLocationIcon,
  FooterContantUsInformationPhoneDataWrap,
  FooterContantUsInformationPhoneIcon,
  FooterContantUsInformationTypography,
  FooterContantUsInformationWrap,
  FooterContantUsNavigationButton,
  FooterContantUsNavigationWrap,
} from "./footerUI/FooterUI";
import IMAGES from "../../assets/images";
import { Button, IconButton } from "@mui/material";

interface FooterProps {
  goToServices: () => void;
  goToHome: () => void;
  goToContact: () => void;
  goToAboutUs: () => void;
}

const Footer: FC<FooterProps> = ({
  goToServices,
  goToAboutUs,
  goToContact,
  goToHome,
}) => {
  const handleOpenPDF = () => {
    const filePath = `${process.env.PUBLIC_URL}/ISOCertificate.pdf`;
    window.open(filePath, "_blank");
  };

  return (
    <MainWrap>
      <DarkShadowWrap>
        <ContentColumnWrap>
          <FooterLogoImg src={IMAGES.FOOTER_LOGO} />
          <FooterFindUs>
            <FooterFindUsTypography sx={{ fontWeight: "100" }}>
              PRONAÐI NAS
            </FooterFindUsTypography>
            <IconButton
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=100063795738865",
                  "_blank"
                )
              }
            >
              <FooterFindUsFacebookIcon />
            </IconButton>
          </FooterFindUs>
          <Button onClick={handleOpenPDF} sx={{ color: "#73B8B4" }}>
            ISO Certifikat
          </Button>
        </ContentColumnWrap>
      </DarkShadowWrap>
      <FooterContantUsWrap>
        <FooterContantUsNavigationWrap>
          <FooterContantUsNavigationButton onClick={goToHome}>
            POČETNA
          </FooterContantUsNavigationButton>
          <FooterContantUsNavigationButton onClick={goToAboutUs}>
            O NAMA
          </FooterContantUsNavigationButton>
          <FooterContantUsNavigationButton onClick={goToServices}>
            USLUGE
          </FooterContantUsNavigationButton>
          <FooterContantUsNavigationButton onClick={goToContact}>
            KONTAKT
          </FooterContantUsNavigationButton>
        </FooterContantUsNavigationWrap>
        <FooterContantUsInformationWrap>
          <FooterContantUsInformationDataWrap>
            <FooterContantUsInformationPhoneIcon />
            <FooterContantUsInformationPhoneDataWrap>
              <FooterContantUsInformationTypography>
                TEL: +387 51 346 600
              </FooterContantUsInformationTypography>
              <FooterContantUsInformationTypography>
                FAX: +387 51 346 601
              </FooterContantUsInformationTypography>
            </FooterContantUsInformationPhoneDataWrap>
          </FooterContantUsInformationDataWrap>
          <FooterContantUsInformationDataWrap>
            <FooterContantUsInformationEmailIcon />
            <FooterContantUsInformationPhoneDataWrap>
              <FooterContantUsInformationTypography>
                cnx@centronix.biz
              </FooterContantUsInformationTypography>
              <FooterContantUsInformationTypography>
                info@centronix.biz
              </FooterContantUsInformationTypography>
            </FooterContantUsInformationPhoneDataWrap>
          </FooterContantUsInformationDataWrap>
          <FooterContantUsInformationDataWrap sx={{ display: "flex" }}>
            <FooterContantUsInformationLocationIcon />
            <FooterContantUsInformationTypography>
              BRAĆE PODGORNIKA 49B, 78000 BANJA LUKA
            </FooterContantUsInformationTypography>
          </FooterContantUsInformationDataWrap>
        </FooterContantUsInformationWrap>
      </FooterContantUsWrap>
    </MainWrap>
  );
};

export default Footer;
