import React, { useState } from "react";
import {
  ContactFormWrap,
  ContactInformationJIBWrap,
  ContactInformationJIPTypography,
  ContactInformationTypography,
  ContactInformationTypographyWrap,
  ContactInformationWrap,
  ContantUsInformationDataWrap,
  ContantUsInformationEmailIcon,
  ContantUsInformationLocationIcon,
  ContantUsInformationPhoneDataWrap,
  ContantUsInformationPhoneIcon,
  ContantUsInformationTypography,
  ContantUsInformationWrap,
  HowToReachUsButton,
  HowToReachUsButtonWrap,
  MainWrap,
} from "./contactUI/ContactUI";
import Hexagon from "./components/Hexagon";
import { Modal } from "@mui/material";
import HowToReachUsModal from "./HowToReachUsModal";

const Contact = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <MainWrap>
      <ContactInformationWrap>
        <ContactInformationTypographyWrap>
          <ContactInformationTypography>
            <strong>KONTAKT</strong> INFORMACIJE
          </ContactInformationTypography>
        </ContactInformationTypographyWrap>
        <ContactInformationJIBWrap>
          <ContactInformationJIPTypography>
            <strong>CENTRONIX D.O.O. BANJA LUKA</strong>
          </ContactInformationJIPTypography>
          <ContactInformationJIPTypography>
            JIB 400791650006
          </ContactInformationJIPTypography>
        </ContactInformationJIBWrap>
        <ContantUsInformationWrap>
          <ContantUsInformationDataWrap>
            <ContantUsInformationPhoneIcon />
            <ContantUsInformationPhoneDataWrap>
              <ContantUsInformationTypography>
                TEL: +387 51 346 600
              </ContantUsInformationTypography>
              <ContantUsInformationTypography>
                FAX: +387 51 346 601
              </ContantUsInformationTypography>
            </ContantUsInformationPhoneDataWrap>
          </ContantUsInformationDataWrap>
          <ContantUsInformationDataWrap>
            <ContantUsInformationEmailIcon />
            <ContantUsInformationPhoneDataWrap>
              <ContantUsInformationTypography>
                cnx@centronix.biz
              </ContantUsInformationTypography>
              <ContantUsInformationTypography>
                info@centronix.biz
              </ContantUsInformationTypography>
            </ContantUsInformationPhoneDataWrap>
          </ContantUsInformationDataWrap>
          <ContantUsInformationDataWrap>
            <ContantUsInformationLocationIcon />
            <ContantUsInformationTypography>
              BRAĆE PODGORNIKA 49B, 78000 BANJA LUKA
            </ContantUsInformationTypography>
          </ContantUsInformationDataWrap>
          <HowToReachUsButtonWrap>
            <HowToReachUsButton onClick={handleOpen}>
              KAKO DO NAS?
            </HowToReachUsButton>
          </HowToReachUsButtonWrap>
          <Modal open={open} onClose={handleClose}>
            <HowToReachUsModal closeIcon={handleClose} />
          </Modal>
        </ContantUsInformationWrap>
      </ContactInformationWrap>
      <ContactFormWrap>
        <Hexagon />
      </ContactFormWrap>
    </MainWrap>
  );
};

export default Contact;
