import { Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

const MainWrap = styled("div")(() => ({
  position: "relative",
}));

const BackgroundWrap = styled("div")(({ theme }) => ({
  width: "100%",
  height: "730px",
  marginTop: "85px",
  background: `linear-gradient(90deg, #06152F, #73B8B4, #A6D1B2)`,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
const BottomLeftBackgroundWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "60%",
  height: "150px",
  backgroundColor: "white",
  clipPath: "polygon(0 100%, 0 0, 100% 100%)",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const BottomRightBackgroundWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  right: "0",
  width: "40%",
  height: "150px",
  backgroundColor: "white",
  clipPath: "polygon(0 100%, 100% 0, 100% 100%)",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const ContentWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  width: "100%",
  height: "670px",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    background: `linear-gradient(90deg, #06152F, #73B8B4, #A6D1B2)`,
    paddingBottom: "150px",
    marginTop: "50px",
  },
}));

const OurServicesWrap = styled("div")(({ theme }) => ({
  width: "40%",
  height: "670px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "110px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
    height: "auto",
    marginBottom: "100px",
  },
}));

const OurServicesHeading = styled(Typography)(() => ({
  fontSize: "36px",
  color: "white",
  paddingBottom: "105px",
  fontWeight: "100",
}));

const OurServicesButton = styled(Button)(() => ({
  background: "linear-gradient(90deg, #4A598B, #73B8B4, #A6D1B2)",
  color: "white",
  fontSize: "10px",
  fontWeight: "100",
  width: "120px",
  height: "35px",
  border: "1px solid white",
}));

const HexagonsWrap = styled("div")(({ theme }) => ({
  width: "70%",
  height: "670px",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    flexDirection: "column",
    justifyContent: "center",
    gap: "100px",
  },
}));

const FirstRowHexagonsWrap = styled("div")(({ theme }) => ({
  marginTop: "220px",
  display: "flex",
  flexDirection: "column",
  gap: "70px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "100px",
  },
}));

const SecondRowHexagonsWrap = styled("div")(({ theme }) => ({
  marginTop: "70px",
  display: "flex",
  flexDirection: "column",
  gap: "70px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    gap: "100px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ThirdRowHexagonsWrap = styled("div")(({ theme }) => ({
  marginTop: "-80px",
  display: "flex",
  flexDirection: "column",
  gap: "70px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    gap: "100px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export {
  MainWrap,
  BackgroundWrap,
  BottomLeftBackgroundWrap,
  BottomRightBackgroundWrap,
  ContentWrap,
  OurServicesWrap,
  HexagonsWrap,
  OurServicesHeading,
  OurServicesButton,
  FirstRowHexagonsWrap,
  SecondRowHexagonsWrap,
  ThirdRowHexagonsWrap,
};
