import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const MainWrap = styled("div")(() => ({
  marginTop: "60px",
}));

const WhoWeAreTypography = styled(Typography)(() => ({
  fontSize: "24px",
  color: "#6B7074",
  textAlign: "center",
}));

const WhoWeAreDescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#6B7074",
  textAlign: "center",
  padding: "50px 150px 0px 150px",
  [theme.breakpoints.up("xs")]: {
    padding: "5px 50px 0px 50px",
  },
}));

export { MainWrap, WhoWeAreTypography, WhoWeAreDescriptionTypography };
