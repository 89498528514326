import React from "react";
import {
  DescriptionWrap,
  MainWrap,
  SecondWrap,
  ThirdWrap,
} from "./componentsUI/HexagonUI";
import { Box, Typography } from "@mui/material";

const Hexagon = ({ heading, subheading }: any) => {
  return (
    <div
      style={{
        height: "235px",
        width: "280px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MainWrap>
        <SecondWrap />
        <ThirdWrap />
      </MainWrap>
      <DescriptionWrap>
        <Typography
          sx={{ fontSize: "16px", color: "black", fontWeight: "200" }}
        >
          <>{heading}</>
        </Typography>
        <Box>
          <Typography
            sx={{
              fontSize: "11px",
              color: "black",
              display: "flex",
              flexDirection: "column",
              fontWeight: "100",
            }}
          >
            {subheading}
          </Typography>
        </Box>
      </DescriptionWrap>
    </div>
  );
};

export default Hexagon;
