import IMAGES from "../../assets/images";

export const MoreOnUsModalData = [
    { 
        title: "TELEKOMUNIKACIJE", 
        image: IMAGES.MORE_ON_US_TELECOMUNICATIONS,
    },
    { 
        title: "SAOBRAĆAJ", 
        image: IMAGES.MORE_ON_US_TRAFFIC,
    },
    { 
        title: "JAVNO UZBUNJIVANJE", 
        image: IMAGES.MORE_ON_US_ALERTING,
    },
    { 
        title: "LIFTOVI", 
        image: IMAGES.MORE_ON_US_LIFTS,
    },
    { 
        title: "MEDICINSKA SREDSTVA", 
        image: IMAGES.MORE_ON_US_MEDICINE,
    },
    { 
        title: "INFORMATIKA", 
        image: IMAGES.MORE_ON_US_INFORMATIC,
    },
    { 
        title: "SEIZMOLOGIJA", 
        image: IMAGES.MORE_ON_US_SEIZMOLOGY,
    },
  ];