import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const MainWrap = styled("div")(({ theme }) => ({
  width: "100%",
  height: "55px",
  backgroundColor: "#040E20",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    padding: "10px 0 10px 0",
  },
}));

const ContentWrap = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0 123px 0 123px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    margin: "0",
  },
}));

const AllRightReservedWrap = styled("div")(() => ({}));

const AllRightReservedTypography = styled(Typography)(() => ({
  fontSize: "9px",
  color: "white",
}));

const GetInTouchWrap = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

const GetInTouchTypography = styled(Typography)(() => ({
  fontSize: "9px",
  color: "white",
}));

export {
  MainWrap,
  ContentWrap,
  AllRightReservedWrap,
  GetInTouchWrap,
  AllRightReservedTypography,
  GetInTouchTypography,
};
