import logoHeader from './images/logo_header.svg';
import underHeaderImage from './images/under_header_image.svg';
import underHeaderImageCover from './images/under_header_image_cover.svg';
import oneClientHeader from './images/one_client_header.svg';
import footerLogo from './images/footer_logo.svg';
import motorola from './images/motorola.svg';
import icom from './images/icom.svg';
import moreOnUsTelecomunications from './images/more_on_us_telecomunications.svg';
import moreOnUsLifts from './images/more_on_us_lifts.svg';
import moreOnUsMedicine from './images/more_on_us_medicine.svg';
import moreOnUsAlerting from './images/more_on_us_alerting.svg';
import moreOnUsInformatic from './images/more_on_us_informatic.svg';
import moreOnUsTraffic from './images/more_on_us_traffic.svg';
import moreOnUsSeizmology from './images/more_on_us_seizmology.svg';

const IMAGES = {
  LOGO_HEADER: logoHeader,
  UNDER_HEADER_IMAGE: underHeaderImage,
  UNDER_HEADER_IMAGE_COVER: underHeaderImageCover,
  ONE_CLIENT_HEADER:oneClientHeader,
  FOOTER_LOGO:footerLogo,
  MOTOROLA:motorola,
  ICOM:icom,
  MORE_ON_US_TELECOMUNICATIONS: moreOnUsTelecomunications,
  MORE_ON_US_LIFTS: moreOnUsLifts,
  MORE_ON_US_MEDICINE: moreOnUsMedicine,
  MORE_ON_US_ALERTING: moreOnUsAlerting,
  MORE_ON_US_INFORMATIC: moreOnUsInformatic,
  MORE_ON_US_TRAFFIC: moreOnUsTraffic,
  MORE_ON_US_SEIZMOLOGY: moreOnUsSeizmology,
};

export default IMAGES;
