import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const MainWrap = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  backgroundColor: "#01172B",
  display: "flex",
  flexDirection: "column",
}));

const CloseButtonWrap = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "end",
}));

const CloseButtonIcon = styled(CloseIcon)(() => ({
  color: "white",
  fontSize: "32px",
}));

const HeadingWrap = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const HeadingTypography = styled(Typography)(() => ({
  color: "white",
  fontSize: "27px",
  fontWeight: "bolder",
}));

const ContentWrap = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexGrow: "1",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
}));

export {
  MainWrap,
  CloseButtonWrap,
  CloseButtonIcon,
  HeadingWrap,
  HeadingTypography,
  ContentWrap,
};
