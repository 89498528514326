import React, { FC } from "react";
import {
  CompanyDescriptionTypography,
  CompanyNameTypography,
  ContactUsButton,
  ContentColumnWrap,
  DarkShadowWrap,
  MainWrap,
  FindUsWrap,
  FindUsFacebookIcon,
} from "./under_headerUI/UnderHeaderUI";
import { IconButton, Typography } from "@mui/material";

interface UnderHeaderProps {
  goToContact: () => void;
}

const UnderHeader: FC<UnderHeaderProps> = ({ goToContact }) => {
  return (
    <MainWrap>
      <DarkShadowWrap>
        <ContentColumnWrap>
          <CompanyNameTypography>CENTRONIX</CompanyNameTypography>
          <CompanyDescriptionTypography>
            "NUDIMO NE SAMO PROIZVODE I USLUGE, VEĆ I POUZDANOST, KVALITET i
            PREDANOST SVIM NAŠIM KLIJENTIMA."
          </CompanyDescriptionTypography>
          <ContactUsButton onClick={goToContact}>
            KONTAKTIRAJ NAS!
          </ContactUsButton>
        </ContentColumnWrap>
      </DarkShadowWrap>
      <FindUsWrap>
        <Typography color="white" fontWeight="100">
          PRONAÐI NAS
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=100063795738865",
              "_blank"
            )
          }
        >
          <FindUsFacebookIcon />
        </IconButton>
      </FindUsWrap>
    </MainWrap>
  );
};

export default UnderHeader;
