import React from "react";
import { IconButton } from "@mui/material";
import {
  CloseButtonWrap,
  MainWrap,
  CloseButtonIcon,
  HeadingWrap,
  HeadingTypography,
  ContentWrap,
} from "./contactUI/HowToReachUsModalUI";

const HowToReachUsModal = (props: any) => {
  const { closeIcon } = props;

  return (
    <MainWrap>
      <CloseButtonWrap>
        <IconButton onClick={closeIcon}>
          <CloseButtonIcon />
        </IconButton>
      </CloseButtonWrap>
      <HeadingWrap>
        <HeadingTypography>KAKO DO NAS?</HeadingTypography>
      </HeadingWrap>
      <ContentWrap>
        <iframe
          title="Centronix Google maps location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.452923022446!2d17.20757877611398!3d44.79195747804023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475e03baccc5377f%3A0xeb1612c07ca01471!2sCentronix!5e0!3m2!1ssr!2sba!4v1699433655019!5m2!1ssr!2sba"
          width="100%"
          height="100%"
          loading="lazy"
        />
      </ContentWrap>
    </MainWrap>
  );
};

export default HowToReachUsModal;
