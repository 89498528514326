import React, { FC, useRef } from "react";
import Header from "../header/Header";
import { MainWrap } from "./homeUI/HomeUI";
import UnderHeader from "../under_header/UnderHeader";
import AboutUs from "../about_us/AboutUs";
import OurServices from "../our_services/OurServices";
// import OurClients from "../our_clients/OurClients";
import Contact from "../contact/Contact";
import CopyrightFooter from "../footer/CopyrightFooter";
import Footer from "../footer/Footer";

const Home: FC = () => {
  const homeRef = useRef<HTMLDivElement | null>(null);
  const aboutUsRef = useRef<HTMLDivElement | null>(null);
  const servicesRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);

  const goToHome = () => {
    homeRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const goToAboutUs = () => {
    aboutUsRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const goToServices = () => {
    servicesRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const goToContact = () => {
    contactRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <MainWrap>
      <div>
        <Header
          goToHome={goToHome}
          goToAboutUs={goToAboutUs}
          goToServices={goToServices}
          goToContact={goToContact}
        />
      </div>
      <div ref={homeRef}>
        <UnderHeader goToContact={goToContact} />
      </div>
      <div ref={aboutUsRef}>
        <AboutUs />
      </div>
      <div ref={servicesRef}>
        <OurServices />
      </div>
      {/* <div>
        <OurClients />
      </div> */}
      <div ref={contactRef}>
        <Contact />
      </div>
      <div>
        <Footer
          goToHome={goToHome}
          goToAboutUs={goToAboutUs}
          goToServices={goToServices}
          goToContact={goToContact}
        />
      </div>
      <div>
        <CopyrightFooter />
      </div>
    </MainWrap>
  );
};

export default Home;
