import { Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  CloseButtonWrap,
  MainWrap,
  CloseButtonIcon,
  HeadingWrap,
  HeadingTypography,
  ContentWrap,
  ArrowWrap,
  LeftArrowIcon,
  RightArrowIcon,
  MainContentWrap,
  MainContentDescription,
  MainContentDescriptionTypography,
  VerticalDivider,
  MainContentImage,
  MainContentImageWrap,
  TelekomunikacijeDiv,
  TelekomunikacijeDivDva,
  MainContentImageWrapTelekomunikacije,
} from "./our_servicesUI/MoreOnUsModalUI";
import { MoreOnUsModalData } from "./MoreOnUsModalData";

const MoreOnUsModal = (props: any) => {
  const { closeIcon } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevItem = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else if (currentIndex === 0) {
      setCurrentIndex(MoreOnUsModalData.length - 1);
    }
  };

  const nextItem = () => {
    if (currentIndex < MoreOnUsModalData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (currentIndex === MoreOnUsModalData.length - 1) {
      setCurrentIndex(0);
    }
  };

  return (
    <MainWrap>
      <CloseButtonWrap>
        <IconButton onClick={closeIcon}>
          <CloseButtonIcon />
        </IconButton>
      </CloseButtonWrap>

      {MoreOnUsModalData.map((item, index) => {
        const isCurrentItem = currentIndex === index;

        return (
          <div
            key={index}
            style={{
              display: isCurrentItem ? "flex" : "none",
              flexDirection: isCurrentItem ? "column" : "row",
              flex: "1",
            }}
          >
            <HeadingWrap>
              <HeadingTypography>{item.title}</HeadingTypography>
            </HeadingWrap>
            <ContentWrap>
              <ArrowWrap>
                <IconButton onClick={prevItem}>
                  <LeftArrowIcon />
                </IconButton>
              </ArrowWrap>
              <MainContentWrap>
                <MainContentDescription>
                  <MainContentDescriptionTypography>
                    {item.title === "TELEKOMUNIKACIJE" ? (
                      <div>
                        <div>
                          <Typography>
                            PROJEKTOVANJE, ORGANIZACIJA I ODRŽAVANJE RADIO MREŽA
                            I LINKOVA.
                          </Typography>
                          <Typography>IZVOĐENJE RADOVA NA TERENU.</Typography>
                          <Typography>
                            IZNAJMLJIVANJE KOMUNIKACIJSKOG KONTEJNERA I
                            ANTENSKOG STUBA ZA SVE VRSTE TELEKOMUNIKACIJA,
                            PLANINA KOZARA, VRH LISINA
                          </Typography>
                          <Typography>
                            PRODAJA I SERVIS RADIO UREĐAJA, REPETITORA, ANTENA I
                            DRUGE OPREME ZA RADIOKOMUNIKACIJE, POZNATIH
                            SVJETSKIH PROIZVOĐAČA:
                          </Typography>
                        </div>
                        <TelekomunikacijeDiv
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://www.motorolasolutions.com/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                MOTOROLA
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://www.icomeurope.com/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                ICOM
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://www.kenwood.com/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                KENWOOD
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://www.yaesu.com/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                YAESU
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://hytera-europe.com/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                HYTERA
                              </Button>
                            </li>
                          </div>
                          <TelekomunikacijeDivDva>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://amphenolprocom.com/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                AMPHENOL PROCOM
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://www.sirioantenne.it/en/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                SIRIO
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://www.trival-antennas-masts.com/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                TRIVAL
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  window.open(
                                    "https://www.diamondantenna.net/",
                                    "_blank"
                                  );
                                }}
                                sx={{ color: "#73B8B4" }}
                              >
                                DIAMOND
                              </Button>
                            </li>
                          </TelekomunikacijeDivDva>
                        </TelekomunikacijeDiv>
                      </div>
                    ) : item.title === "SAOBRAĆAJ" ? (
                      <div>
                        PRODAJA, SERVIS I ODRŽAVANJE UREĐAJA I SISTEMA ZA
                        PRIKUPLJANJE I OBRADU PODATAKA O BROJU, BRZINI I VRSTI
                        VOZILA U SAOBRAĆAJU: BROJAČI SAOBRAĆAJA POZNATOG
                        PROIZVOĐAČA{" "}
                        <Button
                          sx={{
                            color: "#73B8B4",
                          }}
                          onClick={() => {
                            window.open("https://www.dinaf.nl/", "_blank");
                          }}
                        >
                          <strong>Dinaf</strong>
                        </Button>
                        .
                      </div>
                    ) : item.title === "JAVNO UZBUNJIVANJE" ? (
                      <div>
                        PROJEKTOVANJE, PRODAJA, SERVIS I ODRŽAVANJE UREĐAJA I
                        SISTEMA ZA JAVNO OBAVJEŠTAVANJE I UZBUNJIVANJE. SISTEMI
                        ZA JAVNO OBAVJEŠTAVANJE I UZBUNJIVANJE POZNATOG
                        PROIZVOĐAČA
                        <Button
                          sx={{ color: "#73B8B4" }}
                          onClick={() => {
                            window.open(
                              "https://www.comtel.gr/language/en/homepage-main/",
                              "_blank"
                            );
                          }}
                        >
                          <strong>Comtel</strong>
                        </Button>
                      </div>
                    ) : item.title === "LIFTOVI" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Typography>
                          PRODAJA, SERVIS I ODRŽAVANJE LIFTOVA. DIJELOVI I
                          SISTEMI ZA LIFTOVE OD POZNATIH PROIZVOĐAČA:
                        </Typography>
                        <div>
                          <li>
                            <Button
                              onClick={() => {
                                window.open("https://daka.rs/", "_blank");
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              DAVID PAJIĆ DAKA
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://www.schindler.rs/sr.html",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              SCHINDLER
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://www.orona-group.com/int-en/",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              ORONA
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://kleemannlifts.com/company/contact/kleemann-liftovi",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              KLEMANN
                            </Button>
                          </li>
                        </div>
                      </div>
                    ) : item.title === "MEDICINSKA SREDSTVA" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Typography>
                          PRODAJA I SERVIS PLASMA STERILIZATORA, MAŠINA ZA
                          PRANJE LABORATORIJSKOG POSUĐA, RAČUNARA SA GOVORNIM
                          SOFTVEROM ZA SLIJEPA I SLABOVIDA LICA, PO POTREBI I
                          DRUGA MEDICINSKA SREDSTVA. POTROŠNI MATERIJAL ZA
                          STERILIZACIJU.
                        </Typography>
                        <div>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://cisagroup.it/en/",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              CISA
                            </Button>
                          </li>
                        </div>
                      </div>
                    ) : item.title === "INFORMATIKA" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Typography>
                          PRODAJA I SERVIS PERSONALNIH RAČUNARA, ŠTAMPAČA I
                          KOMUNIKACIONE OPREME. SARADNJA SA PRIZNATIM
                          PROIZVOĐAČIMA:
                        </Typography>
                        <div>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://support.hp.com/",
                                  "_blank"
                                );
                              }}
                              sx={{
                                color: "#73B8B4",
                                justifyContent: "flex-start",
                              }}
                            >
                              HP
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://www.lenovo.com/rs/sr",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              LENOVO
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://www.dell.com/en-ba/lp",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              DELL
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open("https://www.canon.ba/", "_blank");
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              CANON
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://www.brother.ba/",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              BROTHER
                            </Button>
                          </li>
                        </div>
                      </div>
                    ) : item.title === "SEIZMOLOGIJA" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Typography>
                          PRODAJA I SERVIS SEIZMOLOŠKIH INSTRUMENATA, SARADNJA
                          SA PRIZNATIM PROIZVOÐAČIMA:
                        </Typography>
                        <div>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://www.lunitek.it/",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              LUNITEK
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://www.lenovo.com/rs/sr",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              LENOVO
                            </Button>
                          </li>
                          <li>
                            <Button
                              onClick={() => {
                                window.open(
                                  "https://kinemetrics.com/",
                                  "_blank"
                                );
                              }}
                              sx={{ color: "#73B8B4" }}
                            >
                              KINEMETRICS
                            </Button>
                          </li>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </MainContentDescriptionTypography>
                </MainContentDescription>
                <VerticalDivider />

                {item.title === "TELEKOMUNIKACIJE" ? (
                  <MainContentImageWrapTelekomunikacije>
                    <MainContentImage
                      src={item.image}
                      alt="More on us visual description"
                    />
                  </MainContentImageWrapTelekomunikacije>
                ) : (
                  <MainContentImageWrap>
                    <MainContentImage
                      src={item.image}
                      alt="More on us visual description"
                    />
                  </MainContentImageWrap>
                )}
              </MainContentWrap>
              <ArrowWrap>
                <IconButton onClick={nextItem}>
                  <RightArrowIcon />
                </IconButton>
              </ArrowWrap>
            </ContentWrap>
          </div>
        );
      })}
    </MainWrap>
  );
};

export default MoreOnUsModal;
